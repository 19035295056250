import React from 'react'
import { PageProps } from 'gatsby'
import ServiceBanner from '../../components/ServiceBanner'
import ServiceContent from '../../components/ServiceContent'
import Button from '../../components/Button'
import { magasinLink } from '../../data/menu'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'

const title = 'Sauvegarde & Transfert de données (RGPD)'

const SauvegardeDeDonneesPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Grâce à nos logiciels experts, nous prenons soin de vos données, ne craignez plus jamais de les perdre ! En 5 minutes vos problèmes disparaissent !"
        />
      </Helmet>

      <ServiceBanner
        title={title}
        background="/images/services/9_RGPD/rgpd-banner.jpg"
        alt={'Bannière RGPD'}
        parallax
      />

      <ServiceContent>
        <ServiceContent.Paragraph>
          Quel que soit votre besoin : sauvegarde, transfert ou suppression de
          vos données, tous nos magasins Save sont équipés de la solution
          Piceasoft, certifiée RGPD.
        </ServiceContent.Paragraph>
        <Button.ExternalLink href={magasinLink}>
          Trouver mon magasin
        </Button.ExternalLink>
        <ServiceContent.Title>Sauvegarde et restauration</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Nos magasins peuvent sauvegarder vos données avant une réparation et
          les réintégrer lorsque l'intervention est terminée. Mais nos experts
          peuvent également réaliser une sauvegarde sécurisée avec mot de passe
          sur un support externe (clé USB, disque dure externe ou Cloud
          personnel). Attention ! Si l'appareil dont vous voulez effectuer une
          sauvegarde est endommagé, nos techniciens ne pourront peut-être pas
          vous sauvez la mise. Alors n'attendez pas qu'il soit trop tard avant
          de tout sauvegarder !
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Transfert</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Notre outil permet à nos magasins d'effectuer le transfert de vos
          données extrêmement rapidement vers votre nouvel appareil. Aucune
          donnée n'est sauvegardée localement pendant le transfert : vos données
          sont donc transférées en toute sécurité !
        </ServiceContent.Paragraph>
        <ServiceContent.Title>Suppression</ServiceContent.Title>
        <ServiceContent.Paragraph>
          Vous souhaitez revendre votre smartphone à un particulier ou à un
          organisme mais vous aimeriez avoir la certitude qu'il ne reste plus
          aucune de vos données ? Save arrive à la rescousse ! Grâce à notre
          outil professionnel, nos magasins peuvent effacer vos données en moins
          d'une minute avec la méthode "Cryptographic sanitization - OS reset".
          Et hop ! Votre appareil sera vierge de toute information personnelle
          en un temps record !
        </ServiceContent.Paragraph>
      </ServiceContent>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Sauvegarde, transfert et/ou suppression de vos données',
  breadcrumb: [servicesBreadcrumbElement, { label: title }],
}

export default Object.assign(SauvegardeDeDonneesPage, {
  layoutProps,
})
